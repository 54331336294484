<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <div> Home </div>
  </div>
</template>

<style lang="scss" scoped>
.home {
  text-align: center;

  div {
    text-align: left;
  }
}
</style>
